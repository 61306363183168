import { QueryClient } from '@tanstack/react-query'
import {
  ActionFunction,
  LoaderFunction,
  useLoaderData as baseUseLoaderData,
} from 'react-router'
import { z } from 'zod'

/**
 * Use this type to define a route action with an access to `queryClient`
 *
 * @example
 * // routes/login.page.tsx
 * export const action: QueryActionFunction = (queryClient) => async ({ request }) => {}
 */
export type QueryActionFunction = (queryClient: QueryClient) => ActionFunction

/**
 * Helper function for defining a route action with an access to `queryClient`
 *
 * @example
 * // routes/user.page.tsx
 * export const action = q.action((queryClient) => async ({ request }) => {})
 */
export const action = <Fn extends QueryActionFunction>(fn: Fn) => fn

/**
 * Use this type to define a route loader with an access to `queryClient`
 *
 * @example
 * // routes/user.page.tsx
 * export const loader: QueryLoaderFunction = (queryClient) => async ({ request }) => {}
 */
export type QueryLoaderFunction = (queryClient: QueryClient) => LoaderFunction

/**
 * Helper function for defining a route loader with an access to `queryClient`
 *
 * @example
 * // routes/user.page.tsx
 * export const loader = q.loader((queryClient) => async () => {})
 */
export const loader = <Fn extends QueryLoaderFunction>(fn: Fn) => fn

export type LoaderData<Loader extends (...args: any) => any> =
  FilterOutResponse<Awaited<ReturnType<ReturnType<Loader>>>>

type FilterOutResponse<T> = T extends Response ? never : T

/**
 * Return strongly-typed loader data from QueryLoaderFunction
 *
 * @example
 * const loader = q.loader(() => () => Promise.resolve({ user: "name" }))
 * export function Component() {
 *   q.useLoaderData<typeof loader>() // return type is { user: string }
 * }
 */
export const useLoaderData: <
  Loader extends (...args: any) => any,
>() => LoaderData<Loader> = baseUseLoaderData as any

/**
 * Schema to validate an error returned by action.
 *
 * @example
 * export const action = () => () => {...}
 * export function Component() {
 *   const fetcher = useFetcher()
 *   useEffect(() => {
 *     if (fetcher.state === 'idle' && fetcher.data) {
 *       const { serverError, validationErrors } = q.actionErrorSchema.parse(fetcherData)
 *       if (serverError) {...}
 *       if (validationErrors) {...}
 *     }
 *   }, [fetcher.state, fetcher.data])
 * }
 */
export const actionErrorSchema = z
  .object({
    serverError: z
      .string()
      .optional()
      .catch(() => {
        console.warn('`serverError` should be a string')
        return undefined
      }),
    validationErrors: z
      .record(z.string())
      .optional()
      .catch(() => {
        console.warn(`"validationError" should be a Record<string, string>`)
        return undefined
      }),
  })
  .default({})
  .catch(() => ({}))

/**
 * You can use this type to enforce returned error type in action
 */
export type ActionError = z.infer<typeof actionErrorSchema>
