import { redirect } from 'react-router'
import { auth } from '~/lib/auth'
import { q } from '~/lib/query'

export const loader = q.loader((_) => async ({ request, params }) => {
  const token = auth.getToken()

  if (!token) {
    /* Show login page if no token */
    return null
  }

  /* Decide where to redirect user */
  const url = new URL(request.url)
  const next = url.searchParams.get('next')
  if (next) {
    return redirect(next)
  }

  const organizationSlug = params['organizationSlug']
  if (organizationSlug) {
    return redirect(`/${organizationSlug}`)
  }

  const user = auth.getUserPayload()
  if (user?.organizationSlug) {
    return redirect(`/${user.organizationSlug}`)
  }

  /* TODO: get organization from the token and redirect there */
  return redirect('/profile')
})
