import { invariant } from '@epic-web/invariant'
import { Params } from 'react-router'
import { getOrganizationParams } from '~/routes/(auth)/(org)/utils'

export function getProjectParams(params: Params) {
  const { organizationSlug } = getOrganizationParams(params)
  const projectSlug = params['projectSlug']
  invariant(projectSlug, "Can't extract projectSlug from the URL")
  return { organizationSlug, projectSlug }
}
