import { BugIcon, CopyIcon, XIcon } from 'lucide-react'
import { useState } from 'react'
import { FallbackProps } from 'react-error-boundary'
import { useRouteError } from 'react-router'
import { toast } from 'sonner'
import { AccessibleIcon } from '~/components/accessible-icon'
import { Button } from '~/components/button'
import { TextLink } from '~/components/text-link'
import { Tooltip } from '~/components/ui/tooltip'
import { _dev_mode_ } from '~/lib/env'
import { useClipboard } from '~/lib/hooks'

type ErrorProps = {
  message: string
  error?: Error | undefined
}

function Shell(props: ErrorProps) {
  return (
    <div className="h-full w-full overflow-auto p-2">
      <div className="flex h-auto min-h-screen w-full flex-col items-center justify-center gap-6">
        <div>
          <p className="text-center text-body-2 text-app-text-hi">
            Application error.
            <br />
            <TextLink to={window.location.href}>
              Reload the application.
            </TextLink>
          </p>
        </div>

        <span className="select-none text-body-2 font-medium text-app-text-lo">
          OR
        </span>

        <div className="flex flex-col items-center gap-2">
          <p className="text-body-3 text-app-text">
            Reset the state of application, sign out, and reload
          </p>
          <Button
            variant="bordered"
            tone="primary"
            size="sm"
            onClick={() => {
              localStorage.clear()
              window.location.reload()
            }}
          >
            Reset the application
          </Button>
        </div>

        {_dev_mode_ ? (
          <DebugInfo message={props.message} error={props.error} />
        ) : null}
      </div>
    </div>
  )
}

export function DebugInfo(props: ErrorProps) {
  const [showDebug, setShowDebug] = useState(false)

  const name = props.error instanceof Error ? props.error.name : undefined

  const [copyToClipboard] = useClipboard()

  return showDebug ? (
    <div className="relative m-4 flex max-h-[320px] w-[480px] max-w-full flex-col gap-3 rounded bg-app-overlay/4 p-3">
      {name ? (
        <p className="font-mono text-body-3 text-app-text-lo">
          Debug info: <span className="text-app-text">{name}</span>
        </p>
      ) : (
        <p className="font-mono text-body-3 text-app-text-lo">Debug info</p>
      )}
      <pre className="max-w-full overflow-auto whitespace-pre-wrap break-words px-0 font-mono text-body-3 text-app-text">
        {props.message}
      </pre>

      <button
        type="button"
        className="group absolute right-1 top-1"
        onClick={() => setShowDebug(false)}
      >
        <AccessibleIcon label="Hide debug info">
          <XIcon size={14} />
        </AccessibleIcon>
      </button>

      <button
        type="button"
        className="group absolute bottom-1 right-1"
        onClick={() => {
          const content = name ? `${name}: ${props.message}` : props.message
          copyToClipboard(content)
          toast.success('Copied to clipboard')
        }}
      >
        <AccessibleIcon label="Copy debug info to clipboard">
          <CopyIcon size={12} />
        </AccessibleIcon>
      </button>
    </div>
  ) : (
    <>
      <div className="absolute bottom-5 left-5">
        <Tooltip.Provider>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <Button
                variant="ghost"
                icon
                className="group"
                onClick={() => setShowDebug(true)}
              >
                <AccessibleIcon label="Show debug info">
                  <BugIcon
                    size={16}
                    className="transition group-hover:rotate-12 group-hover:scale-125"
                  />
                </AccessibleIcon>
              </Button>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content side="right" sideOffset={8}>
                Show debug info
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
      </div>
    </>
  )
}

export function RootErrorBoundary({ error }: FallbackProps) {
  let message = error instanceof Error ? error.message : String(error)

  if (_dev_mode_) {
    console.error('RootErrorBoundary >>>', error)
  }

  return <Shell message={message} error={error} />
}

export function RootRouteErrorBoundary() {
  const error = useRouteError()

  if (_dev_mode_) {
    console.error('Router RootErrorBoundary >>> routeError >>>', error)
  }

  let message = error instanceof Error ? error.message : String(error)
  let optionalError = error instanceof Error ? error : undefined

  return <Shell message={message} error={optionalError} />
}
