import { Route } from 'react-router'

export function getSystemRoutes(lazy: (...args: any[]) => any) {
  return (
    <Route
      path="/system"
      lazy={() => import('~/routes/(auth)/system/layout').then(lazy)}
    >
      <Route
        index
        lazy={() => import('~/routes/(auth)/system/system.page').then(lazy)}
      />
      <Route
        path="labs"
        lazy={() => import('~/routes/(auth)/system/labs.page').then(lazy)}
      />
      <Route
        path="colors"
        lazy={() => import('~/routes/(auth)/system/colors.page').then(lazy)}
      />
      <Route
        path="contained-layout"
        lazy={() =>
          import('~/routes/(auth)/system/contained-layout.page').then(lazy)
        }
      />
      <Route
        path="contained-layout-aside"
        lazy={() =>
          import('~/routes/(auth)/system/contained-layout-aside.page').then(
            lazy,
          )
        }
      />
      <Route
        path="uncontained-layout"
        lazy={() =>
          import('~/routes/(auth)/system/uncontained-layout.page').then(lazy)
        }
      />
      <Route
        path="library"
        lazy={() => import('~/routes/(auth)/system/library.page').then(lazy)}
      />

      <Route
        path="projects"
        lazy={() => import('~/routes/(auth)/system/projects.page').then(lazy)}
      />
    </Route>
  )
}
