import { QueryClient } from '@tanstack/react-query'
import { redirect } from 'react-router'
import { authQueries } from '~/feat/auth/queries'
import { userQueries } from '~/feat/user/queries'
import { auth } from '~/lib/auth'
import { fetchRefreshToken } from '~/lib/http'
import { q } from '~/lib/query'

async function ensureUserAuthAndExtendedToken(
  queryClient: QueryClient,
): Promise<boolean> {
  try {
    await Promise.all([
      queryClient.ensureQueryData(authQueries.extendedToken()),
      queryClient.ensureQueryData(userQueries.info()),
    ])
    return true
  } catch {
    return false
  }
}

/**
 * Ensures that only authenticated user can access children routes
 */
export const loader = q.loader((queryClient) => async ({ request, params }) => {
  let token = auth.getToken()
  const refreshToken = auth.getRefresh()

  /* Try to refresh token */
  if (!token && refreshToken) {
    const res = await fetchRefreshToken(refreshToken)
    if (res) {
      const { accessToken, refreshToken } = res.payload
      auth.setToken(accessToken)
      auth.setRefresh(refreshToken)
      console.log('💧 token refreshed')
      token = accessToken
    }
  }
  /* If token exists ensure we can fetch the user object and targetAccounts from extended token */
  if (token) {
    if (await ensureUserAuthAndExtendedToken(queryClient)) {
      return null
    }
  }

  /* Log out otherwise */
  auth.clearAll()

  /* Save the target URL to redirect to after login */
  const url = new URL(request.url)
  const search = new URLSearchParams()
  search.set('next', `${url.pathname}${url.search}`)

  /* Redirect to organization login if organization is in URL */
  const org = params['organizationSlug']
  if (org) {
    return redirect(`/${org}/login?${search}`)
  }
  return redirect(`/login?${search}`)
})
