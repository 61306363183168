import { redirect } from 'react-router'
import { projectQueries } from '~/feat/projects/queries'
import { q } from '~/lib/query'
import { getProjectParams } from '~/routes/(auth)/[project]/utils'

export const loader = q.loader((queryClient) => async ({ params }) => {
  const { organizationSlug, projectSlug } = getProjectParams(params)
  const { payload: project } = await queryClient.ensureQueryData(
    projectQueries.info(organizationSlug, projectSlug),
  )

  if (!project.scanned) {
    return redirect(`/${organizationSlug}/projects/${project.name}/setup`)
  }

  return null
})
