import '~/styles/app.css'
import '~/styles/font-jetbrains-mono.css'
import '~/styles/font-metropolis.css'
import { MutationCache, QueryClient, matchQuery } from '@tanstack/react-query'
import { createRoot } from 'react-dom/client'
import { App } from '~/app'
import { shouldThrowLayoutError } from '~/lib/error-helpers'
import { createRouter } from '~/router'

/* reload page on chunk loading error, see https://vitejs.dev/guide/build#load-error-handling */
window.addEventListener('vite:preloadError', () => {
  // TODO we might need to add a local (or session) level flag to break on consequent reloads to prevent deathloop
  window.location.reload()
})

export async function main() {
  const queryClient = new QueryClient({
    mutationCache: new MutationCache({
      // eslint-disable-next-line max-params
      onSuccess: (_data, _variables, _context, mutation) => {
        const invalidates = mutation.meta?.invalidates

        if (!invalidates) {
          queryClient.invalidateQueries()
          return
        }

        queryClient.invalidateQueries({
          predicate: (query) => {
            // invalidate all matching tags at once
            return invalidates.some((queryKey) =>
              matchQuery({ queryKey }, query),
            )
          },
        })
      },
    }),
    defaultOptions: {
      queries: {
        retry: false,
        retryOnMount: true,
        throwOnError: (error) => shouldThrowLayoutError(error),
      },
    },
  })

  const router = createRouter(queryClient)

  const rootDiv = document.getElementById('root')
  if (rootDiv) {
    createRoot(rootDiv).render(
      <App queryClient={queryClient} router={router} />,
    )
  } else {
    throw new Error(`Can't find root element to render the app!`)
  }
}

const ENABLE_MSW = false

async function enableMSW() {
  if (process.env.NODE_ENV !== 'development' || !ENABLE_MSW) {
    return
  }
  return import('~/testing/browser').then(({ worker }) => worker.start())
}

enableMSW().then(main).catch(console.error)

/* TODO: add this to the index.html */
// function rehydrateTheme() {
//   try {
//     let docEl = document.documentElement
//     let docClasses = docEl.classList
//     docClasses.remove('light', 'dark')
//     let theme = localStorage.getItem('userway-ca11y-theme')
//     if ('system' === theme || !theme) {
//       let t = '(prefers-color-scheme: dark)',
//         m = window.matchMedia(t)
//       if (m.media !== t || m.matches) {
//         docEl.style.colorScheme = 'dark'
//         docClasses.add('dark-theme')
//       } else {
//         docEl.style.colorScheme = 'light'
//         docClasses.add('light-theme')
//       }
//     } else if (theme) {
//       let x = { light: 'light-theme', dark: 'dark-theme' }
//       docClasses.add(x[theme as keyof typeof x] || '')
//     }
//     if (theme === 'light' || theme === 'dark') docEl.style.colorScheme = theme
//   } catch (e) {}
// }
