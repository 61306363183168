import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Provider as StoreProvider } from 'jotai'
import { useHydrateAtoms } from 'jotai/react/utils'
import { queryClientAtom } from 'jotai-tanstack-query'
import { StrictMode } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { RouterProvider } from 'react-router/dom'
import { Toaster } from 'sonner'
import { RootErrorBoundary } from '~/components/error-boundary/root-error'
import { FontSwitcher, ThemeSwitcher } from '~/feat/theme/components'
import { store } from '~/lib/store'
import { type RouterType } from '~/router'

function HydrateAtoms({
  children,
  queryClient,
}: {
  children: React.ReactNode
  queryClient: QueryClient
}) {
  useHydrateAtoms([[queryClientAtom, queryClient]])
  return children
}

export function App(props: { queryClient: QueryClient; router: RouterType }) {
  const { queryClient, router } = props
  return (
    <StrictMode>
      <ErrorBoundary FallbackComponent={RootErrorBoundary}>
        <StoreProvider store={store}>
          <QueryClientProvider client={queryClient}>
            <HydrateAtoms queryClient={queryClient}>
              <RouterProvider router={router} />
              <ReactQueryDevtools client={queryClient} />
              <Toaster />
              <ThemeSwitcher />
              <FontSwitcher />
            </HydrateAtoms>
          </QueryClientProvider>
        </StoreProvider>
      </ErrorBoundary>
    </StrictMode>
  )
}
